import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banner from '../../assets/images/cloud-private-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from '../storage/index.module.less';
import Button from '../../components/Button';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';
import { cls } from '../../utils';

export const NICE_LIST = [
  {
    title: '超强AI算力',
    desc: '支持 32 TOPS INT8, 16 TFLOPS FP16/BF16, 2 TFLOPS FP32 峰值算力，超高算力利用率，支持32路高清视频智能分析；支持32路H.265/H.264 1080p@25fps 视频硬件解码；支持12路H.265/H.264 1080p@25fps视频硬件编码。',
  },
  {
    title: '丰富的扩展接口',
    desc: '千兆以太网口、USB3.0、HDMI、RS232、RS232/422/485。',
  },

  {
    title: '自然散热技术',
    desc: '无风扇全金属导冷设计，无音无尘；从架构到硬件均采用低功耗设计，典型功耗仅20W。',
  },
  {
    title: '高可靠',
    desc: '可在-20℃~60℃环境稳定运行，适应户外高低温变化环境。电路板三防加固，防振、防尘、防水（IP40），抗电磁干扰。',
  },
  {
    title: '多模式通信',
    desc: '灵活适配千兆网、WiFi、5G等多种网络连接方式，采用多模兼容设计，实现多网络无缝衔接。',
  },
  {
    title: '硬加密',
    desc: '采用硬件加密方式，支持SM2/SM3/SM4国密算法。',
  },
];

export default function CloudPrivatePage() {
  const [machActive, setMachActive] = useState(1);
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="边缘计算微服务器"
        desc="边缘计算微服务器是部署在网络边缘的计算设备，旨在快速处理来自附近数据源的大量实时数据，减少延迟，提高响应速度。它通过本地化的数据处理和分析，为物联网、自动驾驶、远程医疗等应用提供高效、可靠的服务支持。"
      />
      <SectionWrap
        title="土星云边缘计算微服务器"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.machContainer}>
          <div className={styles.machSwiper}>
            <div className={styles.imgViewCard}>
              <img src={`/saturn-product/se110s/${machActive}.jpg`} alt="" />
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              height={80}
              loop={false}
              navigation
              modules={[Navigation]}
              style={{ padding: '0 40px' }}>
              {Array.from({ length: 4 }).map((item, i) => {
                const index = i + 1;
                let itemClassName = styles.machItem;
                if (index === machActive) {
                  itemClassName += ' ' + styles.machItemActive;
                }
                return (
                  <SwiperSlide key={index} style={{ width: 180 }}>
                    <div
                      className={itemClassName}
                      onClick={() => {
                        setMachActive(index);
                      }}>
                      <img src={`/saturn-product/se110s/${index}.jpg`} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={styles.machInfo}>
            <div className={styles.machTitle}>土星云边缘计算微服务器SE110S-WA32</div>
            <div className={styles.machDesc}>
              土星云边缘计算微服务器SE110S-WA32，作为一款卓越的工业智能边缘计算设备，严格遵循行业计算标准。算力高达32TOPS(@INT8)，不仅具备出色的运算能力，还实现了低功耗运行，充分满足工业场景应用需求。SE110S-WA32S在设计上注重防护与加固，能够轻松应对极端工作环境的挑战，确保在各种恶劣条件下能稳定可靠地运行。同时，通过深度集成多样化的深度学习算法，SE110S-WA32实现了视频结构化、精准人脸识别、行为分析以及实时状态监测等高级功能，为智慧水利、交通、能源、社区以及工业等领域提供了强大的边缘侧数字化支持。
            </div>
            <div className={styles.machOperate}>
              <a
                href="/saturn-product/se110s/doc.pdf"
                download="土星云边缘计算微服务器SE110S-WA32产品规格书">
                <Button type="primary" style={{ marginRight: 20 }}>
                  资料下载
                </Button>
              </a>
              <Button
                type="outline"
                onClick={() => {
                  contactUsModalShow();
                }}>
                购买咨询
              </Button>
            </div>
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品特性" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          {NICE_LIST.map((item, index) => {
            return (
              <div className={styles.niceItem} key={index}>
                <img className={styles.niceIcon} src={`/images/cloud-private-nice-5.png`} alt="" />
                <div className={styles.niceTitle}>{item.title}</div>
                <div className={styles.niceDesc}>{item.desc}</div>
              </div>
            );
          })}
          {/* <div className={styles.niceItem} style={{ visibility: 'hidden' }}></div> */}
        </div>
      </SectionWrap>
      <SectionWrap title="技术规格">
        <table className={cls(styles.normsTable, styles.type2)}>
          <colgroup>
            <col width={160} />
          </colgroup>

          <tbody>
            <tr>
              <td>产品型号</td>
              <td>SE110S-WA32</td>
            </tr>
            <tr>
              <td>CPU</td>
              <td>8核 ARM A53 @2.3GHz</td>
            </tr>
            <tr>
              <td>AI算力</td>
              <td>32 TOPS INT8</td>
            </tr>
            <tr>
              <td>内存</td>
              <td>16GB</td>
            </tr>
            <tr>
              <td>存储</td>
              <td>64GB eMMC, 1xM.2 SATA3.0 (选配)</td>
            </tr>
            <tr>
              <td>视频编解码</td>
              <td>
                支持32路H.265/H.264 1080p@25fps 视频解码 支持12路H.265/H.264 1080p@25fps 视频编码
              </td>
            </tr>
            <tr>
              <td>通信</td>
              <td>1x WiFi(选配)，1x 5G 模块(选配)</td>
            </tr>
            <tr>
              <td>I/O扩展</td>
              <td>前置：2x 1Gbps RJ45、2x RS232/422/485 后置：1x RS232、1x HDMI_TX、2x USB3.0</td>
            </tr>
            <tr>
              <td>电源</td>
              <td>DC12V±10%</td>
            </tr>
            <tr>
              <td>工作温度/湿度</td>
              <td>工作温度：-20℃~60℃ 湿度：5%~95%（非凝结）</td>
            </tr>
            <tr>
              <td>存储温度</td>
              <td>-20℃~+85℃</td>
            </tr>
            <tr>
              <td>防护设计</td>
              <td>防尘和防水（IP40）</td>
            </tr>
            <tr>
              <td>安装</td>
              <td>支持壁挂/机架安装</td>
            </tr>
            <tr>
              <td>认证</td>
              <td>3C/节能</td>
            </tr>
            <tr>
              <td>尺寸</td>
              <td>180mm*175mm*79mm</td>
            </tr>
            <tr>
              <td>典型功耗</td>
              <td>20W</td>
            </tr>
            <tr>
              <td>重量</td>
              <td>&lt;2.5kg</td>
            </tr>
            <tr>
              <td>操作系统</td>
              <td>Ubuntu操作系统</td>
            </tr>
            <tr>
              <td>其他</td>
              <td>5000米高海拔适用</td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="私有云" />;
}
